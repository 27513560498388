.icr-header {
  font-weight: 600;
  font-size: 32px;
  margin: 50px 0 20px 0;
}

.icr-investments-header {
  font-size: 24px;
  font-weight: 600;
  margin: 40px 0 10px 0;
}

.icr-investment {
  font-size: 20px;
  font-weight: 500;
  margin: 5px 0;
}
