.simple-input-checkbox {
  margin-right : 0.2rem;
}

.simple-input-label {
  margin : 0.03rem 0.06rem;
  padding : 0.1rem 0.2rem;
}

.simple-input-label-span {
  background-color : '#e9ecef';
  border : 1px solid #ced4da;
}

.simple-input-text {
  margin : 0.03rem 0rem;
  padding : 0.1rem 1rem;
  border : 1px solid #ced4da;
}

.simple-input-masked-text {
  margin : 0.03rem 0rem;
  padding : 0.3rem 1rem;
  border : 1px solid #ced4da;
}

.DayPickerInput-Overlay {
  z-index: 99999999;
}

.simple-input-date-clear {
  cursor: pointer;
  margin-left: 10px;
}
