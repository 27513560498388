@import '../../styles/custom';

.searchIcon {
  color: $main-text-light-color;
  padding: 0;
}
.searchButton {
  margin: 0;
}
.searchPadding input {
  margin-left: 15px;
}
