.reports-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.reports-header {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

.grid {
  width:100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
  grid-gap: 10px;
}


.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  h4 {
    margin: 15px 0;
  }
  .inputs-wrapper {
    @extend .grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
  }
  .columns-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .checkbox-wrapper {
    @extend .grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr) );
    grid-gap: 30px;
    margin-bottom: 50px;
    &-brokers {
      @extend .grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) );
    }
    &-sources-info {
      @extend .grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) );
    }
    &-stages {
      @extend .grid;
      grid-template-columns: repeat(auto-fill, minmax(175px, 1fr) );
    }
  }
  .column {
    margin: 25px 0;
    width: 100%;
  }
}
